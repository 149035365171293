import React, { Component } from 'react'

import Layout from '../components/layout'
import Helmet from 'react-helmet'
import { Footer } from '../components/footer'
import { Captcha } from '../components/captcha'

import '../styles/services.scss'
import { LanguageContext } from '../contexts/Language'

const data = {
  services: {
    title: {
      pt: 'SERVIÇOS',
      en: 'SERVICES'
    },
    desc: {
      pt: 'Como podemos te ajudar',
      en: 'How we can help you'
    }
  },
  mentorship: {
    title: {
      pt: 'MENTORIA FINANCEIRA',
      en: 'MENTORSHIP'
    },
    desc: {
      pt: 'A Mentoria Financeira tem como objetivo central prestar suporte ao empreendedor na tomada de decisões. Junto a ele buscamos entender os contextos (financeiro, operacional e de mercado) da empresa para planejar e auxiliar na implementação de mudanças para uma melhor gestão financeira. Em paralelo, vamos entendendo e avaliando os pontos chave de acompanhamento da empresa, as suas alavancas. A partir dessas informações é possível identificar gargalos, pontos de ineficiência e dar visibilidade sobre margens, fluxo de caixa e capacidade operacional. Tendo feito essas etapas conseguimos projetar as otimizações a serem aplicadas e acompanhamos as implementações provendo suporte, aplicando e transmitindo nosso conhecimento em todas as etapas do processo.',
      en: 'Our Financial Mentorship has as its central objective supporting the entrepreneur in the decision-making process. Together, we seek to understand your company to plan and assist in the implementation of changes for better financial management. We also work to understand and evaluate the company’s key monitoring points. Based on all the information, we identify bottlenecks, and points of inefficiency and provide visibility into margins, cash flow, and operational capacity. Having completed these steps, we’re able to design the optimizations to be applied, providing support and guidance through all stages of the process.'
    }
  },
  studies: {
    title: {
      pt: 'ESTUDOS ECONÔMICOS',
      en: 'ECONOMIC STUDIES'
    },
    desc: {
      pt: 'Estudos econômicos com o objetivo de avaliar a sustentabilidade financeira de uma ideia. Seja uma uma nova linha de receita, uma nova unidade ou um novo negócio conseguimos avaliar e sugerir caminhos para a sua sustentabilidade financeira.',
      en: 'Our Economic Studies assess the financial sustainability of an idea. Whether it’s a new line of revenue, a new sector of a company, or a new business, we are able to assess and suggest paths for your financial sustainability.'
    }
  },
  intelligence: {
    title: {
      pt: 'INTELIGÊNCIA FINANCEIRA',
      en: 'FINANCIAL INTELLIGENCE'
    },
    desc: {
      pt: 'Trabalhos específicos com o objetivo de trazer inteligência financeira aos diferentes setores da sua empresa. Seja para aumentar a eficiência da sua área de projetos ou para uma melhor gestão de estoque, analisamos o contexto operacional e trazemos um ponto de vista racional e financeiro para aumentar a eficiência e os resultados do setor.',
      en: 'We bring our financial intelligence to the all of the sectors of your company. Whether to increase the efficiency of your project area, or for a better inventory management, we analyze the operational context and bring a rational and financial point of view to increase efficiency and positive results in each sector.'
    }
  },
  contact: {
    title: {
      pt: 'ENTRE EM CONTATO',
      en: 'CONTACT US'
    }
  },
  name: {
    title: {
      pt: 'NOME',
      en: 'NAME'
    }
  },
  phone: {
    title: {
      pt: 'TELEFONE',
      en: 'PHONE'
    }
  },
  message: {
    title: {
      pt: 'MENSAGEM',
      en: 'MESSAGE'
    },
    desc: {
      en: 'Type your message here',
      pt: 'Escreva sua mensagem aqui'
    }
  },
  send: {
    title: {
      pt: 'ENVIAR',
      en: 'SEND'
    }
  },
  location: {
    title: {
      pt: 'Localização',
      en: 'Location'
    }
  }
}

class Services extends Component {
  constructor(props) {
    super(props)
    this.submitRef = React.createRef()
    this.state = {
      show: false,
      captchaValue: ''
    }
  }
  showCaptcha = e => {
    e.preventDefault()
    this.setState((state) => ({ ...state, show: true }))
  }
  render() {
    return (
      <Layout>
        <LanguageContext.Consumer>
          {({ language }) => (<>
            <Helmet title="Barlavento - Services" />

            {/* HEADER */}
            <header className="services_header">
              <img
                src={require('../assets/logo1.svg')}
                className="logo"
                alt="logo"
              />

              <h1 className="servicos">{data.services.title[language]}</h1>

              <div className="tracinho" />

              <p>{data.services.desc[language]}</p>

              <div className="wrapper_image_services">
                <div className="services_header_pic" alt="services_header_pic" />
              </div>
            </header>

            {/* SECTION 1 */}
            <section className="services_section1">
              <div className="div1">
                <h1 className="mentoria">{data.mentorship.title[language]}</h1>

                <div className="serv_quadro_1" />

                <p className="mentoria-txt">
                  {data.mentorship.desc[language]}
                </p>

                <dir className="blanc_1" />

                <img
                  src={require('../assets/arrow_long.svg')}
                  className="arrow_1"
                  alt="arrow_1"
                />

                <img
                  src={require('../assets/art_1.svg')}
                  className="art_1"
                  alt="art_1"
                />
              </div>

              <div className="div2">
                <img
                  src={require('../assets/art_2.svg')}
                  className="art_2"
                  alt="art_2"
                />

                <h1 className="estruturacao">{data.studies.title[language]}</h1>

                <div className="serv_quadro_2" />

                <p className="um-trabalho-celere">
                  {data.studies.desc[language]}
                </p>
              </div>

              <dir className="blanc_2" />

              <img
                src={require('../assets/arrow_long.svg')}
                className="arrow_2"
                alt="arrow_2"
              />

              <div className="div3">
                <h1 className="avaliacao">{data.intelligence.title[language]}</h1>

                <div className="serv_quadro_3" />

                <p className="avaliacao-txt">
                  {data.intelligence.desc[language]}
                </p>

                <dir className="blanc_3" />

                <img
                  src={require('../assets/arrow_long.svg')}
                  className="arrow_3"
                  alt="arrow_3"
                />

                <img
                  src={require('../assets/art_3.svg')}
                  className="art_3"
                  alt="art_3"
                />
              </div>

              <div id="contact_anchor" />

              <h1 className="entre-em-contato">{data.contact.title[language]}</h1>
            </section>

            {/* SECTION 2 */}
            <section className="services_section2">
              <div className="sec2_background" />

              <form
                id="intro-form"
                action={process.env.GATSBY_API_URL}
                method="POST"
              >
                <label htmlFor="intro-name" className="contact-name">
                  {data.name.title[language]}
                </label>

                <input
                  type="text"
                  id="name"
                  name="nome"
                  placeholder="João da Silva"
                  required
                />

                <label htmlFor="intro-tel" className="contact-telefone">
                  {data.phone.title[language]}
                </label>

                <input
                  type="tel"
                  id="tel"
                  name="telefone"
                  placeholder="(61) 99988-0099"
                  required
                />

                <label htmlFor="intro-email" className="contact-email">
                  E-MAIL
                </label>

                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="seu@email.com.br"
                  required
                />

                <label htmlFor="intro-email" className="contact-mensagem">
                  {data.message.title[language]}
                </label>

                <textarea
                  id="mensagem"
                  name="mensagem"
                  placeholder={data.message.desc[language]}
                />
                <button ref={e => { this.submitRef = e }} type='submit' hidden />
                <input value={this.state.captchaValue} name='captchaValue' hidden />
                {!this.state.show ?
                  <button onClick={this.showCaptcha} className="button-form">
                    {data.send.title[language]}
                  </button>
                  :
                  <div id='captcha'>
                    <Captcha show={this.state.show}
                      verifyCallback={this.verifyCallback}
                      recaptchaLoaded={this.recaptchaLoaded}
                      submit={(value) => {
                        this.setState(state => ({ ...state, captchaValue: value }))
                        value.length && this.submitRef.click()
                        this.setState(state => ({ ...state, show: false }))
                      }} />
                  </div>
                }
              </form>
            </section>

            {/* SECTION 3 */}
            <section className="services_section3" id="section3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.115228959955!2d-47.894885099999996!3d-15.797877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3b8392a6ee3d%3A0x17a39db48033e1f0!2sBarlavento%20Estrat%C3%A9gia%20Financeira!5e0!3m2!1spt-BR!2sbr!4v1718647022721!5m2!1spt-BR!2sbr" 
                frameBorder="0"
                title={data.location.title[language]}
              />
            </section>

            {/* FOOTER */}
            <section className="services_footer">
              <Footer />
            </section>
          </>)
          }
        </LanguageContext.Consumer>
      </Layout>
    )
  }
}

export default Services
